import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const messageList = async () => {
  try {
    const response = await instance.get(`${apiPath}/messages/display`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
