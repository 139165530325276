import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment-timezone";
import { getJobLogsList } from "../../../../services/logService";
import { dateToLocalTime, durationBetween, durationFormat, parsePercent } from "../../../../utils/common";
import { LogItem } from "../../../../models/log";
import { Select as ChakraSelect } from "chakra-react-select";
import { taskStatus } from "../../../../constants/constants";

interface UploadProps {
  refresh: number;
}
const JobLogsList: React.FC<UploadProps> = ({refresh}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const toast = useToast();
  const [tableList, setTableList] = useState<LogItem[]>([]);
  const [stats, setStats] = useState<{
    total: number,
    total_success: number,
    total_success_rate: string,
    total_fail: number,
    total_fail_rate: string,
    total_duration: number,
    total_duration_success: number,
    total_duration_success_rate: string,
    total_duration_fail: number,
    total_duration_fail_rate: string,
  }>({
    total: 0,
    total_success: 0,
    total_fail: 0,
    total_duration: 0,
    total_duration_success: 0,
    total_duration_fail: 0,
    total_success_rate: '0%',
    total_fail_rate: '0%',
    total_duration_success_rate: '0%',
    total_duration_fail_rate: '0%',
  });

  const tableFiled = [
    t("log.fields.taskName"),
    t("log.fields.startDate"),
    t("log.fields.finishDate"),
    t("log.fields.duration"),
    t("log.fields.taskStatus"),
  ];

  useEffect(() => {
    fetchLogsList();
  }, [refresh]);

  const sumDuration = (data: LogItem[]) => {
    return _.sumBy(data, (item: LogItem) =>{
      return item.duration.duration
    });
  }

  const fetchLogsList = (data?: { taskName?: string, startDate?: string, finishDate?: string, status?: string }) => {
    getJobLogsList(data).then((res) => {
      const data = res.map(item => {
        return {
          ...item,
          duration: durationBetween(item.startDate, item.finishDate)
        }
      })
      setTableList(_.orderBy(data, "startDate", "desc"));
      const totalStats = {
        total: data.length,
        total_success: data.filter(item => item.status === 2).length,
        total_fail: data.filter(item => item.status === 3).length,
        total_duration: sumDuration(data),
        total_duration_success: sumDuration(data.filter(item => item.status === 2)),
        total_duration_fail: sumDuration(data.filter(item => item.status === 3)),
      }
      setStats({
        ...totalStats,
        total_success_rate: parsePercent(totalStats.total_success / totalStats.total),
        total_fail_rate: parsePercent(totalStats.total_fail / totalStats.total),
        total_duration_success_rate: parsePercent(totalStats.total_duration_success / totalStats.total_duration),
        total_duration_fail_rate: parsePercent(totalStats.total_duration_fail / totalStats.total_duration),
      });
    });
    
  };

  const _renderCell = (
    row: LogItem,
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td
        py="0.5rem"
        {...extraProps}
        pl="0.2rem"
      >
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const _renderTableRow = (row: LogItem, index: number) => {
    return (
      <Tr
        key={index}
        _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
      >
        {_renderCell(row, row.taskName, "sm", "bold", { minW: "200px" })}
        {_renderCell(row, dateToLocalTime(row.startDate), "sm", "normal", { minW: "200px" })}
        {_renderCell(row, dateToLocalTime(row.finishDate), "sm", "normal")}
        {_renderCell(row, row.duration.displayValue, "md", "normal")}
        {_renderCell(row, t(`dispatch-task.statuses.${row.status}`), "md", "normal")}
      </Tr>
    );
  };

  const handleSubmit = (
    values: { taskName?: string, startDate?: string, finishDate?: string, status?: string, taskStatus?: string },
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if(!!values.startDate && !!values.finishDate) {
      const startDate = moment(values.startDate);
      const endDate = moment(values.finishDate);
      if(startDate.diff(endDate) > 0) {
        toast({
          title: t('invoice.error.date-range'),
          position: "top",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        formikHelpers.setSubmitting(false);
        return;
      }
    }
    values.taskStatus = values.status;
    const filters = _.pickBy(values, item => item !== null && item !== '' );
    fetchLogsList(filters);
    formikHelpers.setSubmitting(false);
  }

  return (
    <>
    <Box mb="1rem" p="1rem" bgColor="#fff">
      <Formik
        initialValues={{ taskName: "", startDate: "", finishDate: "", status: "" }}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form dir="row">
            <Flex columnGap="1rem" alignItems={"center"}>
            <Field name="taskName">
              {({ field, form }) => (
                <FormControl
                flexBasis="200px"
                >
                <FormLabel>
                {t("log.fields.taskName")}:
              </FormLabel>
                  <Input
                      {...field}
                      size="md"
                      placeholder={t(
                        "log.fields.taskName"
                      )}
                      value={field.value.taskName}
                    />
                </FormControl>
              )}
            </Field>
            <Field name="startDate">
                {({ field, form }: any) => (
                  <FormControl flexBasis="200px">
                    <FormLabel>
                      {t("case.create.start-date")}:
                    </FormLabel>
                    <Input
                      {...field}
                      placeholder={t("case.create.start-date")}
                      minW="200px"
                      type="date"
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="finishDate">
                {({ field, form }: any) => (
                  <FormControl flexBasis="200px">
                    <FormLabel>
                      {t("case.create.end-date")}:
                    </FormLabel>
                    <Input
                      {...field}
                      placeholder={t("case.create.end-date")}
                      minW="200px"
                      type="date"
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="status">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.domains && form.touched.domains}
                >
                  <FormLabel>
                    {t("log.fields.taskStatus")}:
                  </FormLabel>
                  <Stack width="200px">
                  <ChakraSelect
                    colorScheme="purple"
                    onChange={(selectedOption) => {
                        form.setFieldValue(field.name, parseInt(selectedOption?.value || ""));
                      }}
                    options={taskStatus.map(status => {
                      return {
                        label: t(`dispatch-task.statuses.${status}`),
                        value: status
                      }
                    })}
                  />
                  </Stack>
                  <FormErrorMessage>
                    {form.errors.domains}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              colorScheme="teal"
              type="submit"
              size="sm"
              ml="1rem"
              isLoading={formikProps.isSubmitting}
            >
              {t("common.btn.search")}
            </Button>
            </Flex>
            
          </Form>
        )}
      </Formik>
    </Box>
    <Box mb="1rem" p="1rem" bgColor="#fff">
    <StatGroup>
      <Stat>
        <StatLabel>{t('log.stats.total')}</StatLabel>
        <StatNumber color={"blue"}>{stats.total}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>{t('log.stats.total-success')}</StatLabel>
        <StatNumber color={"green"}>{stats.total_success}</StatNumber>
        <StatHelpText>
          {stats.total_success_rate}
        </StatHelpText>
      </Stat>
      <Stat>
        <StatLabel>{t('log.stats.total-fail')}</StatLabel>
        <StatNumber color={"red"}>{stats.total_fail}</StatNumber>
        <StatHelpText>
        {stats.total_fail_rate}
        </StatHelpText>
      </Stat>
      <Stat>
        <StatLabel>{t('log.stats.total-duration')}</StatLabel>
        <StatNumber color={"blue"}>{durationFormat(stats.total_duration)}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>{t('log.stats.total-duration-success')}</StatLabel>
        <StatNumber color={"green"}>{durationFormat(stats.total_duration_success)}</StatNumber>
        <StatHelpText>
        {stats.total_duration_success_rate}
        </StatHelpText>
      </Stat>
      <Stat>
        <StatLabel>{t('log.stats.total-duration-fail')}</StatLabel>
        <StatNumber color={"red"}>{durationFormat(stats.total_duration_fail)}</StatNumber>
        <StatHelpText>
        {stats.total_duration_fail_rate}
        </StatHelpText>
      </Stat>
    </StatGroup>
    </Box>
    <Box>
      <Box p="1rem" bgColor="#fff" overflowX={{ base: "scroll", md: "hidden" }}>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {tableList.map((row: LogItem, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
    </>
  );
};

export default JobLogsList;
