import instance from "./interceptor";
import { Task } from '../models/task';

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const getAllTasks = async (): Promise<Task[]> => {
    try {
        const response = await instance.get(`${apiPath}/rpa/GetAllTasks`);
        return response.data.map((task: any) => ({
            id: task.id,
            taskName: task.taskName,
            taskPayload: task.taskPayload,
            createdBy: task.createdBy,
            createdDate: task.createdDate,
            updatedBy: task.updatedBy,
            updatedDate: task.updatedDate,
        }));
    } catch (error) {
        console.error("error:", error);
        throw error;
    }
};

export const executeTask = async (vhost: string, queueName: string, payload: string) => {
    try {
        const response = await instance.post('/api/rpa/ExecuteTask', {
            vhost,
            queueName,
            payload
        });
        return response.status;
    } catch (error) {
        console.error("error:", error);
        throw error;
    }
};

export const addOrEditTask = async (operateTask: Task) => {
  try {
    const response = await instance.post(`${apiPath}/rpa/UpsertTask`, operateTask);
    return response.status;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};