import { AddToCartItem, CartItem, SubscribeCartItem } from "../models/cart";
import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const addToMyCart = async (data: AddToCartItem[]) => {
    try {
      const response = await instance.post(`${apiPath}/Cart`, data);
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };

  export const getCartList = async (): Promise<CartItem[]> => {
    try {
      const response = await instance.get(`${apiPath}/Cart`);
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };
  
  export const deleteCartById = async (id: string) => {
    try {
      const response = await instance.delete(`${apiPath}/Cart/${id}`);
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };

  export const subscribeCart = async (data: SubscribeCartItem[]) => {
    try {
      const response = await instance.post(`${apiPath}/Cart/subscribe`, data);
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };