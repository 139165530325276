import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const uploadSingleFile = async (data: any) => {
  try {
    const response = await instance.post(`${apiPath}/file`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
