export const devApiUrl = "https://panel-dev.yuanlite.com:7001/api";

const baseURL =
  process.env.REACT_APP_ENV === "development"
    ? ""
    : "/api"
export const authPaths = [
  baseURL + "/user/login",
  baseURL + "/user/register",
  baseURL + "/user/activate",
  baseURL + "/user/invitation",
  baseURL + "/user/AccessAsGuest",
  baseURL + "/user/forgot-password",
];
export const HISTORICAL_LOGS = [
  {
    version: "1.4.0",
    date: "2024-08-05",
    content: "The Workbench module has been released.",
  },
  {
    version: "1.3.0",
    date: "2024-07-18",
    content: "Homepage restructured and revised.",
  },

  {
    version: "1.2.0",
    date: "2024-06-24",
    content: "The Digital Workforce module has been released.",
  },
  {
    version: "1.1.0",
    date: "2024-06-10",
    content:
      "The case module is released. You can start adding cases belonging to your own organization.",
  },
  {
    version: "1.0.0",
    date: "2024-06-01",
    content: "Initial version released.",
  },
];

export const tagThem = {
  javascript: "green",
  typescript: "green",
  html: "green",
  html5: "green",
  css: "green",
  less: "green",
  sass: "green",
  vue: "green",
  vue2: "",
  vue3: "",
  vite: "",
  react: "",
  angular: "",
  jquery: "green",
  wordpress: "green",
  rpa: "green",
  mysql: "green",
  oracle: "green",
  python: "green",
  java: "green",
  php: "teal",
  "node.js": "green",
  redis: "green",
  shopify: "pink",
  turnkey: "yellow",
};

export const tagList = [
  // 前端开发
  "HTML",
  "CSS",
  "JavaScript",
  "React",
  "Angular",
  "Vue",
  "SASS",
  "LESS",
  "Bootstrap",
  "Tailwind CSS",
  "Antd",
  "Vite",
  "Element UI",
  "Vuetify",
  "Material-UI",
  "Redux",
  "Web3",
  "E-Commerce",
  "Turnkey",
  "jQuery",
  "TypeScript",
  "Next.js",

  // 后端开发
  "Java",
  "Php",
  "Python",
  "Go",
  "Ruby",
  "C#",
  "C++",
  "Scala",
  "C",
  "Rust",
  "Node",
  "Express",
  "Django",
  "Flask",
  "Ruby on Rails",
  "Spring Boot",
  "ASP.NET",
  "Laravel",
  "Symfony",
  "Koa",
  "NestJS",
  "Microservices",
  "Golang",
  "GraphQL",
  "Strapi",

  // 数据库
  "MySQL",
  "PostgreSQL",
  "MongoDB",
  "SQLite",
  "Redis",
  "Oracle Database",
  "Microsoft SQL Server",
  "Cassandra",
  "MariaDB",
  "Elasticsearch",

  //电商平台
  "Shopify",
  "Magento",
  "WooCommerce",
  "BigCommerce",
  "PrestaShop",
  "OpenCart",
  "Shopware",
  "WordPress",
  "Liquid",

  // DevOps与云计算
  "Docker",
  "Kubernetes",
  "Jenkins",
  "Ansible",
  "Terraform",
  "AWS",
  "Azure",
  "Google Cloud Platform (GCP)",
  "GitLab CI/CD",
  "CircleCI",

  // 版本控制
  "Git",
  "GitHub",
  "GitLab",
  "Bitbucket",

  // 移动开发
  "Swift",
  "Kotlin",
  "Flutter",
  "React Native",
  "Xamarin",
  "Objective-C",
  "Java (Android)",

  // 大数据与数据处理
  "Hadoop",
  "Apache Spark",
  "Apache Kafka",
  "Apache Flink",
  "Tableau",
  "Power BI",
  "Talend",
  "Splunk",
  "Cloudera",

  // 测试
  "Selenium",
  "JUnit",
  "Mocha",
  "Jest",
  "Cypress",
  "TestNG",
  "PyTest",
  "Robot Framework",
  "Appium",
  "Postman",
  "RPA",

  //图表
  "Highcharts",
  "ECharts",
  "D3.js",
  "Chart.js",
  "AntV",
  "FusionCharts",
  "Three.js",
  "Recharts",

  //地图
  "Google Maps",
  "Mapbox",
  "Baidu Maps",

  //自定义
  "Custom Design",
  "Online Sales",
  "SVG",
  "Finance",
  "Frontend",
  "Backend",
  "Fullstack",
  "Automation Solutions",
  "Invoice Processing",
  "SAP Integration",
  "Textile Machinery",
  "Automation",
  "Automation in Procurement",
  "Data Maintenance",
  "MES System Integration",
  "Oracle Integration",
  "Production Planning",
  "Data Management",
  "FastAPI",
  "Integration",
  "Permissions",
];

export const categoryList = [
  "Project In-Progress",
  "E-Commerce",
  "Digital Workforce",
  "Team Extension",
];

export const domainList = [
  "HR",
  "Finance",
  "Procurement",
  "Sales & Marketing",
  "Customer Services",
  "E-commerce",
];

export const applicationList = {
  en: [
    "Invoice"
  ],
  cn: [
    "开票"
  ]
};

export const useCaseList = ["Case 1", "Case 2", "Case 3", "Case 4"];
export const platformList = [
  "Platform 1",
  "Platform 2",
  "Platform 3",
  "Platform 4",
];

export const NAV_ITEMS = [
  {
    label: "Inspiration",
    children: [
      {
        label: "Explore Design Work",
        subLabel: "Trending Design to inspire you",
        href: "#",
      },
      {
        label: "New & Noteworthy",
        subLabel: "Up-and-coming Designers",
        href: "#",
      },
    ],
  },
  {
    label: "Find Work",
    children: [
      {
        label: "Job Board",
        subLabel: "Find your dream design job",
        href: "#",
      },
      {
        label: "Freelance Projects",
        subLabel: "An exclusive list for contract work",
        href: "#",
      },
    ],
  },
  {
    label: "Learn Design",
    href: "#",
  },
  {
    label: "Hire Designers",
    href: "#",
  },
];

export const taskStatus = ["0", "1", "2", "3", "4"];
