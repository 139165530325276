import { BlogQuery } from "../models/blog";
import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const getBlogList = async (data?: BlogQuery) => {
  try {
    const response = await instance.get(`${apiPath}/blogs`, { params: data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getBlogDetail = async (blogId: string) => {
  try {
    const response = await instance.get(`${apiPath}/blogs/${blogId}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createOrUpdateBlog = async (data: any) => {
  try {
    const response = await instance.post(`${apiPath}/blogs`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteBlog = async (id: string) => {
  try {
    const response = await instance.delete(`${apiPath}/blogs/draft/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
