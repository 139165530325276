import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
  useColorModeValue,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { Field, FieldProps, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { EditInfo, MemberInfo } from "../../../models/organization";
import {
  deleteMember,
  organizationMemberListWithRole,
  sendInvitation,
  updateMemberRole,
} from "../../../services/organizationService";
import { organizationInviteValidationSchema } from "../../../utils/validation";
import { canEdit } from "../../../utils/auth";
import { FiEdit } from "react-icons/fi";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";

const OrganizationMembers = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const [isAvaliable, setIsAvaliable] = useState<boolean>(true);
  const [tableList, setTableList] = useState<MemberInfo[]>([]);
  const [editInfo, setEditInfo] = useState<EditInfo>();
  const toast = useToast();
  const params = useParams();
  const organizationId = params.id;

  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    {
      label: "menu.organization-management",
      path: "/workbench/organization",
    },
    {
      label: "organization.members.title",
      path: "organization.members.title",
    },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (!!organizationId) {
      organizationMemberListWithRole(organizationId).then((res) => {
        setTableList(res);
      });
    } else {
      setIsAvaliable(false);
    }
  };

  const handleDeleteMember = () => {
    deleteMember(editInfo!)
      .then((res) => {
        init();
        onDeleteModalClose();
        toast({
          title: t("common.btn.delete-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: t("common.btn.delete-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const handleEditOrganizationRole = () => {
    updateMemberRole(editInfo!)
      .then((res) => {
        init();
        onClose();
        toast({
          title: t("common.btn.delete-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: t("common.btn.delete-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const tableFiled = [
    t("organization.members.name"),
    t("organization.members.email"),
    t("organization.list.role"),
    t("organization.members.status"),
    t("common.btn.actions"),
  ];

  const _renderCell = (
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td py="0.5rem" pl="0.2rem" {...extraProps}>
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const _renderTableRow = (row: MemberInfo, index: number) => {
    return (
      <Tr key={index}>
        {_renderCell(row.userName, "sm", "bold")}
        {_renderCell(row.email, "sm", "normal", { minW: "120px" })}
        <Td pl="0.2rem">
          <Flex alignItems="center" color="gray">
            <Text color={row.role === "Admin" ? "black" : "gray"} minW="50px">
              {row.role}
            </Text>
            {row.role !== "Admin" && (
              <FiEdit
                cursor="pointer"
                color="teal"
                onClick={() => {
                  onOpen();
                  setEditInfo({
                    organizationId: row.organizationId,
                    userId: row.userId,
                    role: row.role === "Admin" ? 0 : 1,
                  });
                }}
              />
            )}
          </Flex>
        </Td>
        {_renderCell(
          row.active
            ? t("organization.members.status-1")
            : t("organization.members.status-0"),
          "sm",
          "normal",
          { minW: "120px", color: row.active ? "green" : "red" }
        )}
        <Td py="0.5rem" pl="0.2rem">
          {canEdit() && row.role !== "Admin" && (
            <Button
              size="sm"
              colorScheme="red"
              mr="0.5rem"
              onClick={() => {
                onDeleteModalOpen();
                setEditInfo({
                  organizationId: row.organizationId,
                  userId: row.userId,
                });
              }}
            >
              {t("common.btn.delete")}
            </Button>
          )}
        </Td>
      </Tr>
    );
  };

  const handleSubmit = (
    values: { email: string },
    formikHelpers: {
      setSubmitting: (arg0: boolean) => void;
      setFieldError: (field: string, message: string | undefined) => void;
    }
  ) => {
    const emailExists = tableList.some((item) => item.email === values.email);

    if (emailExists) {
      // 如果邮箱存在，设置错误
      formikHelpers.setFieldError("email", "This email is already invited.");
      formikHelpers.setSubmitting(false);
      return;
    }

    sendInvitation({
      ...values,
      organizationId: organizationId!
    })
      .then((res) => {
        formikHelpers.setSubmitting(false);
        toast({
          title: t("organization.invite.success-msg"),
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((res) => {
        formikHelpers.setSubmitting(false);
        toast({
          title: t("organization.invite.error-msg"),
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };
  return (
    <>
      {isAvaliable ? (
        <Box maxW="1240px" margin="0 auto">
          <Box py="1rem">
            <CustomBreadcrumb routes={breadcrumbRoutes} />
          </Box>
          <Box minH="calc(100vh - 80px)">
            {canEdit() && !!tableList && (
              <Box bgColor="#fff" mb="1rem" p="1rem">
                <Heading pb="1rem">
                  {tableList.length > 0 ? tableList[0].organizationName : ""}
                </Heading>
                <Box>
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={organizationInviteValidationSchema}
                    onSubmit={handleSubmit}
                  >
                    {(formikProps) => (
                      <Form>
                        <Field name="email">
                          {({ field, form }: FieldProps<{ email: string }>) => (
                            <FormControl
                              // mb="24px"
                              isInvalid={
                                !!(form.errors.email && form.touched.email)
                              }
                            >
                              <FormLabel>
                                {t("organization.invite.label")} :
                              </FormLabel>
                              <Box display="flex" alignItems="center">
                                <Input
                                  {...field}
                                  size="md"
                                  maxW={{ base: "12rem", md: "15rem" }}
                                  placeholder={t(
                                    "organization.invite.placeholder"
                                  )}
                                  value={field.value.email}
                                />
                                <Button
                                  leftIcon={<MdEmail />}
                                  colorScheme="teal"
                                  type="submit"
                                  size="sm"
                                  ml="1rem"
                                  isLoading={formikProps.isSubmitting}
                                >
                                  {t("organization.invite.btn")}
                                </Button>
                              </Box>
                              <FormErrorMessage>
                                {form.errors.email as string}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            )}

            <Box
              overflowX={{ base: "scroll", md: "hidden" }}
              bgColor="#fff"
              pb="1rem"
              p="1rem"
            >
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    {tableFiled.map((caption, idx) => {
                      return (
                        <Th
                          color="gray.400"
                          key={idx}
                          ps={idx === 0 ? "0px" : ""}
                        >
                          {caption}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {tableList.map((row: MemberInfo, index) => {
                    return _renderTableRow(row, index);
                  })}
                </Tbody>
              </Table>
            </Box>
          </Box>

          <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader> {t("organization.delete-modal.title")}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>{t("organization.delete-modal.content")}</ModalBody>
              <ModalFooter>
                <Button mr={3} onClick={onDeleteModalClose} size="sm">
                  {t("common.btn.cancel")}
                </Button>
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() => handleDeleteMember()}
                >
                  {t("common.btn.delete")}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("organization.list.edit-role")}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <FormLabel>{t("organization.list.role")}</FormLabel>
                  <Select
                    value={editInfo?.role}
                    onChange={(e) =>
                      setEditInfo({
                        organizationId: editInfo?.organizationId!,
                        userId: editInfo?.userId!,
                        role: Number(e.target.value),
                      })
                    }
                  >
                    <option value="0">Admin</option>
                    <option value="1">User</option>
                  </Select>
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button mr={3} onClick={onClose} size="sm">
                  {t("common.btn.cancel")}
                </Button>
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() => handleEditOrganizationRole()}
                >
                  {t("common.btn.submit")}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      ) : (
        <Box
          maxW="1240px"
          margin="0 auto"
          p="1rem"
          mb="1rem"
          textAlign="center"
        >
          <Text color={"gray.500"} mb={6}>
            {t("organization.notice.title")}
          </Text>
          <Button
            colorScheme="teal"
            bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
            color="white"
            variant="solid"
          >
            <Link
              as={RouterLink}
              to={"/workbench/organization"}
              display="flex"
              alignItems="center"
              style={{ textDecoration: "none" }}
            >
              {t("organization.notice.btn")}
            </Link>
          </Button>
        </Box>
      )}
    </>
  );
};

export default OrganizationMembers;
