import axios from "axios";
import { AuthForm } from "../models/auth";
import {
  EmailDetail,
  ForgotPasswordForm,
  ProfileForm,
  ResetPasswordForm,
} from "../models/user";
import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const login = async (data: AuthForm) => {
  try {
    const response = await instance.post(`${apiPath}/user/login`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const register = async (data: AuthForm) => {
  try {
    const response = await instance.post(`${apiPath}/user/register`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const bind = async (data: AuthForm) => {
  try {
    const response = await instance.post(`${apiPath}/user/bind`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const active = async (userId: string) => {
  try {
    const response = await instance.put(`${apiPath}/user/activate/${userId}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const userList = async () => {
  try {
    const response = await instance.get(`${apiPath}/user`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const userParameter = async () => {
  try {
    const response = await instance.get(`${apiPath}/user/parameter`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const userInitial = async () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
  try {
    const response = await instance.get(`${apiPath}/user/${userInfo.id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const guestLogin = async () => {
  try {
    const response = await instance.post(`${apiPath}/user/AccessAsGuest`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const userInfo = async (userId: string) => {
  try {
    const response = await instance.get(`${apiPath}/profiles/${userId}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateUserInfo = async (data: ProfileForm) => {
  try {
    const response = await instance.post(`${apiPath}/profiles`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const resetPassword = async (data: ResetPasswordForm) => {
  try {
    const response = await instance.post(`${apiPath}/user/ChangePassword`, data);
    return response.data;
  } catch (error) {
    // console.error("error:", error);
    throw error;
  }
};

export const sendEmail = async (email: string) => {
  try {
    const response = await instance.post(
      `${apiPath}/user/SendForgotPasswordEmail/${email}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const sendForgotPasswordEmail = async (email: string) => {
  try {
    const response = await instance.post(`${apiPath}/user/ForgotPassword/${email}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const verifyForgotPasswordCode = async (email: string, code: string) => {
  try {
    const response = await instance.post(
      `${apiPath}/User/ForgotPassword/Verify/${email}/${code}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const forgotPassword = async (data: ForgotPasswordForm) => {
  try {
    const response = await instance.post(`${apiPath}/user/forgotPassword`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchGoogleUserInfo = async (access_token: string) => {
  try {
    const response = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const registrationVerification = async (email: string) => {
  try {
    const response = await instance.post(
      `${apiPath}/User/RegistrationVerification/${email}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updatePrimaryEmail = async (data: EmailDetail) => {
  try {
    const response = await instance.post(`${apiPath}/Profiles/SetEmail`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const removeEmail = async (email: string) => {
  try {
    const response = await instance.delete(`${apiPath}/Profiles/Email/${email}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const verificationEmail = async (email: string) => {
  try {
    const response = await instance.post(`${apiPath}/Profiles/Verification/${email}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const bindEmail = async (email: string, verificationCode: string) => {
  try {
    const response = await instance.post(
      `${apiPath}/Profiles/BindEmail/${email}/${verificationCode}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
