import React, { useContext, useEffect, useRef, useState } from "react";
import { deleteCartById, getCartList, subscribeCart } from "../../services/cartService";
import { CartItem } from "../../models/cart";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  useColorModeValue,
  CheckboxGroup,
  SimpleGrid,
  Checkbox,
  Card,
  Stack,
  CardBody,
  Heading,
  UnorderedList,
  ListItem,
  ListIcon,
  List,
  Button,
  HStack,
  Badge,
  Link,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react'
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { t } from "i18next";
import _ from "lodash";
import { FaCircle } from "react-icons/fa";
import { parseCurrency } from "../../utils/common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CartCountsContext } from "../../App";

interface CartListItem {
  digitalWorkforceName?: string;
  digitalWorkforceType?: number;
  digitalWorkforceId?: string;
  functions: CartItem[];
};

const CartList = () => {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const cancelRef = useRef<any>();
  const { setCartCounts} = useContext(CartCountsContext);


  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.cart", path: "/cart" },
  ];
  const textColor = useColorModeValue("gray.700", "white");

  const formatCartData = (data: CartItem[]) => {
    return _.chain(data).groupBy('digitalWorkforceId').map(item => {
      const workforceData = item.length ? item[0] : undefined;
      if(workforceData) {
        return {
          digitalWorkforceName: workforceData.digitalWorkforceName,
          digitalWorkforceType: workforceData.digitalWorkforceType,
          digitalWorkforceId: workforceData.digitalWorkforceId,
          functions: item
        } as CartListItem;
      }
      return {
        functions: item
      }
    }).value();
  }

    const [cartData, setCartData] = useState<{
      shared: CartListItem[],
      other: CartListItem[], 
      otherTimeTotal?: number,
      otherMonthTotal?: number,
    }>({
      shared: [],
      other: []
    });

    const [cartItems, setCartItems] = useState<CartItem[]>([]);

    const closeDialog = () => {
      onClose();
      fetchCartList();
    }

    useEffect(() => {
        fetchCartList();
      }, []);

      const fetchCartList = () => {
        getCartList().then((res) => {
         if(res) {
          const shareData = formatCartData(res.filter(item => item.digitalWorkforceType === 0));
          const otherData =  formatCartData(res.filter(item => item.digitalWorkforceType !== 0));
          const cartData = {
            shared: shareData,
            other: otherData,
            otherTimeTotal: _.sumBy(res.filter(item => item.costType === 0), 'cost'),
            otherMonthTotal: _.sumBy(res.filter(item => item.costType === 1), 'cost')
          }
          setCartData(cartData);
          setCartItems(res);
          setCartCounts(res.length);
         }
        });
      };

      const FunctionList =({data}: {data: CartListItem[]})=> {
        const removeFunction = (data: CartItem) => {
          deleteCartById(data.id).then((res)=> {
            toast({
              title: t('cart.message.success'),
              status: 'success',
              duration: 3000,
              position: 'top',
              isClosable: true,
            });
            fetchCartList();
          }).catch(()=> {
            toast({
              title: t('cart.message.fail'),
              status: 'error',
              duration: 3000,
              position: 'top',
              isClosable: true,
            })
          });
        };
        
        return <SimpleGrid gap="2" flex={1} flexDir={"column"}>
        {data.map((item) => (
          <Card>
          <Stack>
            <CardBody>
              <Heading size={"md"}>{item.digitalWorkforceName}</Heading>
              <List spacing={3}  mt={4}>
              {item.functions.map((item) => (
                  <ListItem display={"flex"} alignItems={"center"}>
                    <ListIcon as={FaCircle} color='green.500' />
                    <HStack flex={1} justifyContent={'space-between'}>
                      <Text>{item.groupName}</Text>
                      <HStack flexBasis={'60%'} justifyContent={'space-between'}>
                        <Badge data-type='Badge' colorScheme='green'> {parseCurrency(item.cost || 0, item.currency || '')}/{item.costType ? t('release-digital-workforce.function.costType.byMonth') : t('release-digital-workforce.function.costType.byTime')}</Badge>
                        <Button colorScheme="blue" size="xs" onClick={() => removeFunction(item)}>{t('common.btn.remove')}</Button>
                        </HStack>
                      </HStack>
                  </ListItem>
              ))}
              </List>
            </CardBody>
          </Stack>
          </Card>
        ))}
      </SimpleGrid>
      }

      const subscribe = () => {
        const subscribeData = cartItems.map(item => {
          return {
            organizationId: item.organizationId,
            digitalWorkforceId: item.digitalWorkforceId,
            groupId: item.groupId
          }
        });
        subscribeCart(subscribeData).then(res => {
          toast({
            title: t('cart.message.subscribe-success'),
            status: 'success',
            duration: 3000,
            position: 'top',
            isClosable: true,
          });
          onOpen();
        }).catch(()=> {
          toast({
            title: t('cart.message.subscribe-fail'),
            status: 'error',
            duration: 3000,
            position: 'top',
            isClosable: true,
          });
        });
      }

    return <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box p="1rem" bg="white" mb="1rem">
        <Text
          fontSize="xl"
          pb={"1rem"}
          color={textColor}
          fontWeight="bold"
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={'row'}
          alignItems={'flex-end'}
        >
          <Text>{t("menu.cart")}</Text>
          <Link color='teal.500' href='#' fontSize='sm'>{t('cart.go-to-create')}</Link>
        </Text>
      </Box>
      <Box  bg="white" mb="1rem">
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                {t('common.role-type.share')}{t('cart.type')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            {cartData.shared.length 
            ? <FunctionList data={cartData.shared}></FunctionList>
            : '-' }
            
            </AccordionPanel>
          </AccordionItem>
      </Accordion>
      </Box>
      <Box bg="white" mb="1rem">
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <HStack flex={1}>
                <Text as='span' flex='1' textAlign='left'>
                  {t('common.role-type.other')}{t('cart.type')}
                </Text>
                 <HStack>
                   <Badge colorScheme="yellow">{parseCurrency(cartData.otherMonthTotal!)}/{t('release-digital-workforce.function.costType.byMonth')}</Badge>
                 </HStack>
                </HStack>
                
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {cartData.other.length 
              ? <FunctionList data={cartData.other}></FunctionList>
              : '-'}
            </AccordionPanel>
          </AccordionItem>
      </Accordion>
      </Box>
      <Box>
        <HStack justifyContent={"flex-end"}>
          <Button disabled={!cartItems.length} colorScheme="orange" onClick={()=>{subscribe()}}>{t('cart.subscribe')}</Button>
        </HStack>
      </Box>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={closeDialog}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {t('cart.create-role-message')}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={closeDialog}>
              {t('common.btn.cancel')}
            </Button>
            <Button colorScheme='blue' ml={3} onClick={()=>{fetchCartList(); navigate(`/workbench/digital-workforce`)}}>
            {t('common.btn.confirm')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>;
}

export default CartList;