import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  InputRightElement,
  InputGroup,
  Button,
  Image,
  Link,
  Tooltip,
  Badge,
  useToast,
} from "@chakra-ui/react";
import {
  FiMenu,
  FiChevronDown,
  FiLogOut,
  FiUser,
  FiShare2,
  FiUsers,
} from "react-icons/fi";
import { FaShoppingBasket, FaShoppingCart, FaTasks } from "react-icons/fa";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MobileProps } from "../../../models/layout";
import { AuthInfo, UserInfo } from "../../../models/auth";
import { setDefaultOrganization } from "../../../services/organizationService";
import { isGuest, setLocalInfo } from "../../../utils/auth";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { setUserRoles, setUserInfo as _setUserInfo } from "../../../store";
import yuanLiteImage from "../../../assets/img/YuanLite.png";
import { guestLogin } from "../../../services/authService";
import DeskTopNav from "./DeskTopNav";
import { getCartList } from "../../../services/cartService";
import { formatCartCount } from "../../../utils/common";
import { CartCountsContext } from "../../../App";

const Nav: React.FC<MobileProps> = ({ onOpen, ...rest }) => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("en");
  const [organizationList, setOrganizationList] = useState<any[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const { t, i18n } = useTranslation();
  const [shadow, setShadow] = useState(false);
  const {cartCounts, setCartCounts} = useContext(CartCountsContext);
  const toast = useToast();

  const dispatch = useDispatch();

  const fetchCartList = ()=> {
    getCartList().then((res) => {
      if(res) {
        setCartCounts(formatCartCount(res.length));
      }
     });
  }

  useEffect(() => {
    if(!isGuest()) {
      fetchCartList();
    }
    const userInfoJson = localStorage.getItem("userInfo");
    if (userInfoJson) {
      const _userInfo = JSON.parse(userInfoJson);
      setUserInfo(_userInfo);
      setOrganizationList(_userInfo.organizations);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };

  const handleChange = (event: { target: { value: any } }) => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);
    i18n.changeLanguage(selectedValue);
  };

  const singOut = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("userInfo");
    guestLogin().then((res) => {
      setLocalInfo(res);
      const userInfo = res.user;
      let organizationRoles: string[] = [];
      const organizations = userInfo.organizations;
      dispatch(_setUserInfo(userInfo));

      if (!isEmpty(organizations)) {
        const organization = organizations.find(
          (item: any) => item.id === userInfo.currentOrganizationId
        );
        if (!!organization) {
          organizationRoles.push(
            "Organization." + organization.organizationRole
          );
        }
      }
      dispatch(setUserRoles([...userInfo.roles, ...organizationRoles]));
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 1000);
    });
  };

  const setDefaultOrganizationInfo = (id: string) => {
    if (userInfo?.currentOrganizationId === id) return;
    setDefaultOrganization(id)
      .then((res: AuthInfo) => {
        //更新本地的用户信息
        setLocalInfo(res);
        const userInfo = res.user;
        let organizationRoles: string[] = [];
        const organizations = userInfo.organizations;
        setOrganizationList(organizations);
        dispatch(_setUserInfo(userInfo));

        if (!isEmpty(organizations)) {
          const organization = organizations.find(
            (item) => item.id === userInfo.currentOrganizationId
          );
          if (!!organization) {
            organizationRoles.push(
              "Organization." + organization.organizationRole
            );
          }
        }
        dispatch(setUserRoles([...userInfo.roles, ...organizationRoles]));

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch();
  };

  const navigateToCart = () => {
    if(!isGuest()) {
      navigate(`/cart`);
    } else {
      toast({
        title: t('common.message.login-first'),
        status: "info",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
      navigate(`/login`);
    }
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      bgColor="#fff"
      position="fixed"
      top={0}
      zIndex={100}
      boxShadow={shadow ? "md" : "none"}
    >
      <Image
        cursor="pointer"
        onClick={() => navigate(`/`)}
        src={yuanLiteImage}
        maxH="60px"
        display={{ base: "none", md: "block" }}
      ></Image>
      <Flex
        w="100%"
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />
        <DeskTopNav
          onClose={() => {}}
          display={{ base: "none", md: "block" }}
        ></DeskTopNav>
        <HStack spacing={{ base: "1", md: "6" }}>
          <InputGroup>
            <Select
              cursor="pointer"
              defaultValue={selectedValue}
              onChange={handleChange}
            >
              <option value="en">en-US</option>
              <option value="zh">zh-CN</option>
            </Select>
            <InputRightElement pointerEvents="none">
              <MdLanguage color="gray.300" />
            </InputRightElement>
          </InputGroup>
          <Tooltip label={t('cart.go-to-cart')}>
              <Button padding='0' variant={'ghost'} onClick={() => {navigateToCart()}}>
              <FaShoppingCart color="teal" size="20px"  />
              {!!cartCounts && 
              <Flex position={'absolute'} top={'0px'} left={'24px'} fontSize="0.8rem">
                  <Badge colorScheme="red" fontSize={'xs'}>{cartCounts}</Badge>
              </Flex> 
              }              
              </Button>
          </Tooltip>
          
          {isGuest() ? (
            <Flex alignItems="center">
              <Link href="/register">
                <Text
                  minW="2rem"
                  m={{ base: "0.5rem", md: "1rem" }}
                  _hover={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "teal.500",
                  }}
                >
                  {t("common.register")}
                </Text>
              </Link>
              <Link href="/login">
                <Button colorScheme="teal"> {t("common.login")}</Button>
              </Link>
            </Flex>
          ) : (
            <>
            
              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton
                    py={2}
                    transition="all 0.3s"
                    _focus={{ boxShadow: "none" }}
                  >
                    <HStack>
                      <Avatar
                        size={"sm"}
                        name={userInfo?.email || "User"}
                        bg="teal.500"
                        color="white"
                      ></Avatar>
                      <Box display={{ base: "none", md: "flex" }}>
                        <FiChevronDown />
                      </Box>
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuItem bgColor="white" _hover={{ bg: "white" }}>
                      <Box textAlign="center" w="100%" maxHeight={"50vh"} overflowY={'auto'}>
                        {organizationList.map((item, index) => (
                          <Text
                            w="100%"
                            key={index}
                            py="0.5rem"
                            my="0.25rem"
                            color={
                              userInfo?.currentOrganizationId === item.id
                                ? "white"
                                : "gray.800"
                            }
                            bgColor={
                              userInfo?.currentOrganizationId === item.id
                                ? "teal.200"
                                : "white"
                            }
                            textAlign="center"
                            borderRadius="2px"
                            _hover={{
                              bg: "teal.200",
                              cursor: "pointer",
                              color: "white",
                            }}
                            onClick={() => setDefaultOrganizationInfo(item.id)}
                          >
                            {item.name}
                          </Text>
                        ))}
                      </Box>
                    </MenuItem>

                    <MenuDivider />
                    <MenuItem onClick={() => navigate(`/user-management`)}>
                      <FiUsers />
                      <Text pl={2}>{t("menu.user-management")}</Text>
                    </MenuItem>
                    <MenuItem onClick={() => navigate(`/Task-Management`)}>
                      <FaTasks />
                      <Text pl={2}>{t("menu.task-management")}</Text>
                    </MenuItem>

                    <MenuDivider />
                    <MenuItem onClick={() => navigate(`/logs`)}>
                      <FiShare2 />
                      <Text pl={2}>{t("menu.logs")}</Text>
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(`/profile/${userInfo?.id}`)}
                    >
                      <FiUser />
                      <Text pl={2}>{t("menu.profile")}</Text>
                    </MenuItem>

                    <MenuDivider />
                    <MenuItem onClick={singOut}>
                      <Flex flexDirection="column">
                        <Text textAlign="center" fontWeight="700" minW="100px">
                          {userInfo?.email}
                        </Text>
                        <Flex alignItems="center">
                          <FiLogOut color="red" />
                          <Text pl="0.5rem">{t("common.sign-out")}</Text>
                        </Flex>
                      </Flex>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </>
          )}
        </HStack>
      </Flex>
    </Flex>
  );
};

export default Nav;
