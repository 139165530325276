
import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const upsertOrganizationInfo = async (data: any) => {
  try {
    const response = await instance.post(`${apiPath}/organization/upsert`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
