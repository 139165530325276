import {
  FiHome,
  FiAperture,
  FiSettings,
  FiUsers,
  FiGrid,
  FiCopy,
  FiCodepen,
  FiFileText,
  FiBookmark,
  FiShare2,
  FiLayers,
} from "react-icons/fi";
import { MdLibraryBooks, MdShoppingCart } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { RouteItem } from "../models/routeType";
import NotFoundPage from "../pages/Error/404";
import Dashboard from "../pages/Dashboard";
import Settings from "../pages/Settings";
import UserManagement from "../pages/UserManagement";
import { Navigate } from "react-router-dom";
import ForbiddenPage from "../pages/Error/403";
import { hasPermission } from "../utils/auth";
import { useSelector } from "react-redux";
import CaseManagement from "../pages/CaseManagement";
import OrganizationManagement from "../pages/Organizations";
import TaskManagement from "../pages/TaskManagement";
import DigitalWorkforce from "../pages/DigitalWorkforce";
import OrganizationDetails from "../pages/Organizations/Details";
import DigitalWorkforceDetails from "../pages/DigitalWorkforce/Details";
import SubscribedDigitalWorkforceDetails from "../pages/DigitalWorkforce/Subscribed";
import Requirements from "../pages/Requirements";
import CaseDetails from "../pages/CaseManagement/Detail";
import Logs from "../pages/Log";
import Profile from "../pages/Settings/Profile";
import Blogs from "../pages/Blogs";
import BlogDetails from "../pages/Blogs/Details";
import EditBlog from "../pages/Blogs/Edit";
import Privacy from "../pages/Privacy";
import Workbench from "../pages/Workbench";
import WorkbenchBlogs from "../pages/Workbench/Blogs";
import WorkbenchRequirements from "../pages/Workbench/Requirements";
import WorkbenchOrganizations from "../pages/Workbench/Organizations";
import OrganizationMembers from "../pages/Organizations/Members";
import ReleaseDigitalWorkforce from "../pages/Workbench/ReleaseDigitalWorkforce";
import ReleaseDigitalWorkforceTask from "../pages/Workbench/ReleaseDigitalWorkforce/Task";
import ReleaseDigitalWorkforceFunction from "../pages/Workbench/ReleaseDigitalWorkforce/Function";
import ReleaseDigitalWorkforceRole from "../pages/Workbench/ReleaseDigitalWorkforce/Role";
import CustomerDigitalWorkforce from "../pages/Workbench/DigitalWorkforce";
import CustomerDigitalWorkforceTasks from "../pages/Workbench/DigitalWorkforce/Tasks";
import EditCustomerDigitalWorkforce from "../pages/Workbench/DigitalWorkforce/Edit";
import ReleaseDigitalWorkforceClient from "../pages/Workbench/ReleaseDigitalWorkforce/Client";
import CartList from "../pages/Cart";
import WorkbenchDispatch from "../pages/Workbench/Dispatch";
import WorkbenchInvoiceManagement from "../pages/Workbench/Invoice";
import WorkbenchJobLogsManagement from "../pages/Workbench/JobLogs";

interface AuthorizationProps {
  children: any;
  redirectTo: string;
  requiredRoles: string[];
  route?: string;
}

const RequireAuth = ({
  children,
  redirectTo,
  requiredRoles,
}: AuthorizationProps) => {
  const userRoles = useSelector((state: any) => state.userRoles);
  return hasPermission(userRoles, requiredRoles) ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
};

const routes: RouteItem[] = [
  {
    name: "Home",
    path: "/",
    lngStr: "menu.home",
    icon: FiHome,
    element: <Dashboard />,
  },
  // {
  //   name: "Project Marketplace",
  //   path: "/project-marketplace",
  //   lngStr: "menu.project-marketplace",
  //   icon: FiFileText,
  //   element: <Requirements />,
  // },
  {
    name: "Digital Workforce",
    path: "/digital-workforce",
    lngStr: "menu.digital-workforce",
    // isHide: true,
    icon: FiCodepen,
    element: <DigitalWorkforce />,
  },
  {
    name: "Digital Workforce",
    path: "/digital-workforce/details/:id",
    lngStr: "menu.digital-workforce",
    isHide: true,
    icon: FiCodepen,
    element: <DigitalWorkforceDetails />,
  },
  {
    name: "Digital Workforce",
    path: "/workbench/digital-workforce/Subscribed/:id",
    lngStr: "menu.digital-workforce",
    isHide: true,
    icon: FiCodepen,
    element: <SubscribedDigitalWorkforceDetails />,
  },
  {
    name: "Cases",
    path: "/articles/cases/details/:id",
    lngStr: "menu.case-management",
    isHide: true,
    icon: FiGrid,
    element: <CaseDetails />,
  },
  {
    name: "Blogs",
    path: "/articles/blogs/details/:id",
    lngStr: "menu.blogs",
    isHide: true,
    icon: MdLibraryBooks,
    element: <BlogDetails />,
  },
  {
    name: "Blogs",
    path: "/workbench/blogs/edit/:id",
    lngStr: "menu.blogs",
    isHide: true,
    icon: MdLibraryBooks,
    element: <EditBlog />,
  },
  {
    name: "Articles",
    path: "/articles",
    lngStr: "menu.articles",
    // isHide: true,
    icon: MdLibraryBooks,
    // element: <EditBlog />,
    children: [
      {
        name: "Cases",
        path: "/articles/cases",
        lngStr: "menu.case-management",
        // isHide: true,
        icon: FiGrid,
        element: <CaseManagement />,
      },
      {
        name: "Blogs",
        path: "/articles/blogs",
        lngStr: "menu.blogs",
        icon: MdLibraryBooks,
        element: <Blogs />,
      },
    ],
  },

  {
    name: "User Management",
    path: "/user-management",
    lngStr: "menu.user-management",
    icon: FiUsers,
    requiredRoles: ["Admin"],
    isHide: true,
    element: (
      <RequireAuth redirectTo="/403" requiredRoles={["Admin"]}>
        <UserManagement />
      </RequireAuth>
    ),
  },
  {
    name: "Organizations",
    path: "/organizations",
    lngStr: "menu.organization-management",
    icon: FiCopy,
    isHide: true,
    element: <OrganizationManagement />,
  },
  {
    name: "Organization Details",
    path: "/organizations/details/:id",
    lngStr: "menu.organization-management",
    icon: FiCopy,
    isHide: true,
    element: <OrganizationDetails />,
  },
  {
    name: "Task Management",
    path: "/Task-Management",
    lngStr: "menu.task-management",
    requiredRoles: ["Admin"],
    icon: FaTasks,
    isHide: true,
    element: (
      <RequireAuth redirectTo="/403" requiredRoles={["Admin"]}>
        <TaskManagement />
      </RequireAuth>
    ),
  },
  {
    name: "Settings",
    path: "/settings",
    lngStr: "menu.settings",
    isHide: true,
    icon: FiSettings,
    requiredRoles: ["Admin", "Individual", "Organization.Admin"],
    element: (
      <RequireAuth
        redirectTo="/403"
        requiredRoles={["Admin", "Individual", "Organization.Admin"]}
      >
        <Settings />
      </RequireAuth>
    ),
  },
  {
    name: "Profile",
    path: "/profile/:id",
    lngStr: "menu.profile",
    isHide: true,
    icon: FiBookmark,
    element: <Profile />,
  },
  {
    name: "Logs",
    path: "/logs",
    lngStr: "menu.logs",
    icon: FiShare2,
    isHide: true,
    element: <Logs />,
  },
  {
    name: "Privacy",
    path: "/privacy",
    lngStr: "menu.privacy",
    icon: FiShare2,
    isHide: true,
    element: <Privacy />,
  },
  {
    name: "Workbench",
    path: "/workbench",
    lngStr: "menu.workbench",
    isHide: true,
    icon: FiLayers,
    element: <Workbench />,
  },
  {
    name: "Organization Management",
    path: "/workbench/organization",
    lngStr: "menu.organization-management",
    icon: FiLayers,
    isHide: true,
    element: <WorkbenchOrganizations />,
  },
  {
    name: "Organization Members",
    path: "/workbench/organization/members/:id",
    lngStr: "menu.organization-management",
    icon: FiLayers,
    isHide: true,
    element: <OrganizationMembers />,
  },
  {
    name: "Project Marketplace",
    path: "/workbench/project-marketplace",
    lngStr: "menu.project-marketplace",
    icon: FiLayers,
    isHide: true,
    element: <WorkbenchRequirements />,
  },

  {
    name: "Blogs Management",
    path: "/workbench/blogs",
    lngStr: "menu.blogs",
    icon: FiLayers,
    isHide: true,
    element: <WorkbenchBlogs />,
  },
  {
    name: "Dispatch Digital Workforce",
    path: "/workbench/dispatch",
    lngStr: "menu.dispatch",
    icon: FiLayers,
    isHide: true,
    element: <WorkbenchDispatch />,
  },
  {
    name: "Invoice Management",
    path: "/workbench/invoice",
    lngStr: "menu.invoice-management",
    icon: FiLayers,
    isHide: true,
    element: <WorkbenchInvoiceManagement />,
  },
  {
    name: "Job Logs",
    path: "/workbench/log",
    lngStr: "menu.job-logs-management",
    icon: FiLayers,
    isHide: true,
    element: <WorkbenchJobLogsManagement />,
  },
  {
    name: "Digital Workforce",
    path: "/workbench/digital-workforce",
    lngStr: "menu.digital-workforce",
    isHide: true,
    icon: FiCodepen,
    // element: <DigitalWorkforce isFromWorkbench={true} />,
    element: <CustomerDigitalWorkforce />,
  },
  {
    name: "Digital Workforce",
    path: "/workbench/digital-workforce/tasks/:id",
    lngStr: "menu.digital-workforce-function",
    isHide: true,
    icon: FiCodepen,
    // element: <DigitalWorkforce isFromWorkbench={true} />,
    element: <CustomerDigitalWorkforceTasks />,
  },
  {
    name: "Digital Workforce",
    path: "/workbench/digital-workforce/edit/:id",
    lngStr: "menu.blogs",
    isHide: true,
    icon: MdLibraryBooks,
    element: <EditCustomerDigitalWorkforce />,
  },

  {
    name: "Release Digital Workforce",
    path: "/workbench/release-digital-workforce",
    lngStr: "menu.release-digital-workforce",
    isHide: true,
    requiredRoles: ["Admin"],
    icon: FiCodepen,
    element: (
      <RequireAuth redirectTo="/403" requiredRoles={["Admin"]}>
        <ReleaseDigitalWorkforce />
      </RequireAuth>
    ),
  },
  {
    name: "Release Digital Workforce Task",
    path: "/workbench/release-digital-workforce/task",
    lngStr: "release-digital-workforce.items.task",
    isHide: true,
    requiredRoles: ["Admin"],
    icon: FiCodepen,
    element: (
      <RequireAuth redirectTo="/403" requiredRoles={["Admin"]}>
        <ReleaseDigitalWorkforceTask />
      </RequireAuth>
    ),
  },
  {
    name: "Release Digital Workforce Function",
    path: "/workbench/release-digital-workforce/function",
    lngStr: "release-digital-workforce.items.function",
    isHide: true,
    requiredRoles: ["Admin"],
    icon: FiCodepen,
    element: (
      <RequireAuth redirectTo="/403" requiredRoles={["Admin"]}>
        <ReleaseDigitalWorkforceFunction />
      </RequireAuth>
    ),
  },
  {
    name: "Release Digital Workforce Role",
    path: "/workbench/release-digital-workforce/role",
    lngStr: "release-digital-workforce.items.role",
    isHide: true,
    requiredRoles: ["Admin"],
    icon: FiCodepen,
    element: (
      <RequireAuth redirectTo="/403" requiredRoles={["Admin"]}>
        <ReleaseDigitalWorkforceRole />
      </RequireAuth>
    ),
  },
  {
    name: "Release Digital Workforce Client",
    path: "/workbench/release-digital-workforce/client",
    lngStr: "release-digital-workforce.items.client",
    isHide: true,
    requiredRoles: ["Admin"],
    icon: FiCodepen,
    element: (
      <RequireAuth redirectTo="/403" requiredRoles={["Admin"]}>
        <ReleaseDigitalWorkforceClient />
      </RequireAuth>
    ),
  },
  {
    name: "Digital Workforce",
    path: "/cart",
    lngStr: "menu.cart",
    isHide: true,
    icon: MdShoppingCart,
    element: <CartList />,
  },
  {
    name: "404",
    path: "*",
    icon: FiAperture,
    lngStr: "menu.404",
    isHide: true,
    element: <NotFoundPage />,
  },
  {
    name: "403",
    path: "/403",
    icon: FiAperture,
    lngStr: "menu.403",
    isHide: true,
    element: <ForbiddenPage />,
  },
];

export default routes;
