import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Text, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { FileUpload, FileUploadDropzone, FileUploadTrigger } from "@saas-ui/file-upload";
import { useRef, useState } from "react";
import InvoiceList from "./List";
import { uploadInvoice } from "../../../services/invoiceService";
import { useSelector } from "react-redux";
import { UserInfo } from "../../../models/auth";

const WorkbenchJobLogsManagement = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    { label: "menu.job-logs-management", path: "/workbench/log" },
  ];
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [files, setFiles] = useState<File[]>([]);
  const [refresh, setRefresh] = useState(0);

  const toast = useToast();
  const userInfo = useSelector((state: any) => state.userInfo as UserInfo);
  const submitInvoiceFiles = async () => {
    const formdata = new FormData();
    formdata.append("CreaterOrganizationId", userInfo.currentOrganizationId);
    formdata.append("CreatedBy",  userInfo.id);
    files.map(file =>{
      formdata.append("files", file);
    });
    if(files.length) {
      uploadInvoice(formdata).then(()=>{
        toast({
          title: t('invoice.upload.message.success'),
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        });
        setRefresh(refresh+1);
      }).catch(()=> {
        toast({
          title: t('invoice.upload.message.fail'),
          status: 'error',
          duration: 3000,
          position: 'top',
          isClosable: true,
        });
        setRefresh(refresh+1);
      });
    }

    onClose();
  }

  return (
    <Box margin="0 auto" mb="1rem" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box bg={"#fff"} p="1rem" pb="1rem" mb="1rem">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {t("menu.job-logs-management")}
        </Text>    
      </Box>
      
      <InvoiceList refresh={refresh}></InvoiceList>

    </Box>
  );
};

export default WorkbenchJobLogsManagement;
