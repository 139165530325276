import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const getJobLogsList = async (data?: any) => {
  try {
    const response = await instance.get(`${apiPath}/Dashboard/TaskExecutionResults`, { params: data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
