import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/einvoice-api' : '';
export const getInvoiceList = async (data?: any) => {
  try {
    const response = await instance.get(`${apiPath}/invoice`, { params: data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const uploadInvoice = async (data: any) => {
  try {
    const response = await instance.post(`${apiPath}/invoice`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};