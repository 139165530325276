import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const getCaseList = async (organizationId: string) => {
  try {
    const response = await instance.get(
      `${apiPath}/case?organizationId=${organizationId}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const getCaseDetail = async (caseId: string) => {
  try {
    const response = await instance.get(`${apiPath}/case/${caseId}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteCase = async (id: string) => {
  try {
    const response = await instance.delete(`${apiPath}/case?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createOrUpdateCase = async (data: any) => {
  try {
    const response = await instance.post(`${apiPath}/case/upsert`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
