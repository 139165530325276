import { EditInfo } from "../models/organization";
import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const organizationList = async () => {
  try {
    const response = await instance.get(`${apiPath}/organization`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const organizationDetail = async (id: string) => {
  try {
    const response = await instance.get(`${apiPath}/organization/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const organizationMemberList = async (id: string) => {
  try {
    const response = await instance.get(`${apiPath}/organization/${id}/users`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const organizationMemberListWithRole = async (id: string) => {
  try {
    const response = await instance.get(
      `${apiPath}/organization/${id}/UsersWithRole`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getInvitationInfo = async (id: string) => {
  try {
    const response = await instance.get(`${apiPath}/user/invitations/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const sendInvitation = async (data: { email: string, organizationId: string }) => {
  try {
    const response = await instance.post(`${apiPath}/user/sendInvitation`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const setDefaultOrganization = async (organizationId: string) => {
  try {
    const response = await instance.post(
      `${apiPath}/user/organizations/${organizationId}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteMember = async (data: EditInfo) => {
  try {
    const response = await instance.delete(`${apiPath}/organization/user`, { data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateMemberRole = async (data: EditInfo) => {
  try {
    const response = await instance.post(`${apiPath}/organization/SetUserRole`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
