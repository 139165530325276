import { RequirementQuery } from "../models/requirement";
import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const getRequirementsList = async (data?: RequirementQuery) => {
  try {
    const response = await instance.get(`${apiPath}/requirements`, { params: data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createRequirements = async (data: any) => {
  try {
    const response = await instance.post(`${apiPath}/requirements`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getRequirementsById = async (id: string) => {
  try {
    const response = await instance.get(`${apiPath}/requirements/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createRequirementsApplication = async (data: any) => {
  try {
    const response = await instance.post(`${apiPath}/requirements/application`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getApplicationList = async (requirementId: string) => {
  try {
    const response = await instance.get(
      `${apiPath}/requirements/${requirementId}/applications`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getApplicationDetail = async (applicationId: string) => {
  try {
    const response = await instance.get(
      `${apiPath}/requirements/applications/${applicationId}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateApplicationStatus = async (applicationId: string) => {
  try {
    const response = await instance.put(
      `${apiPath}/requirements/applications/${applicationId}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createRequirementTimeLine = async (data: any) => {
  try {
    const response = await instance.post(
      `${apiPath}/requirements/tasktimeline`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getRequirementTimeLine = async (id: string) => {
  try {
    const response = await instance.get(`${apiPath}/requirements/tasktimeline/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteRequirement = async (id: string) => {
  try {
    const response = await instance.delete(`${apiPath}/requirements/draft/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateRequirementStatus = async (
  requirementId: string,
  status: number
) => {
  try {
    const response = await instance.put(
      `${apiPath}/requirements/status/${requirementId}?status=${status}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
