import {
  ReleaseDIgitalWorkforceClientItem,
  ReleaseDIgitalWorkforceFunctionForm,
  ReleaseDIgitalWorkforceTask,
} from "../models/releaseDigitalWorkforce";
import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
//任务
export const getReleaseDigitalWorkforceTaskList = async (data?: any) => {
  try {
    const response = await instance.get(`${apiPath}/DigitalWorkforce/function`, {
      params: data,
    });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getReleaseDigitalWorkforceTaskById = async (id: string) => {
  try {
    const response = await instance.get(`${apiPath}/DigitalWorkforce/function/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createReleaseDigitalWorkforceTask = async (
  data: ReleaseDIgitalWorkforceTask
) => {
  try {
    const response = await instance.post(
      `${apiPath}/DigitalWorkforce/function`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteReleaseDigitalWorkforceTaskById = async (id: string) => {
  try {
    const response = await instance.delete(
      `${apiPath}/DigitalWorkforce/function/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

//职能

export const getReleaseDigitalWorkforceFunctionList = async (data?: any) => {
  try {
    const response = await instance.get(`${apiPath}/DigitalWorkforce/group`, {
      params: data,
    });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getReleaseDigitalWorkforceFunctionById = async (id: string) => {
  try {
    const response = await instance.get(`${apiPath}/DigitalWorkforce/group/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createReleaseDigitalWorkforceFunction = async (
  data: ReleaseDIgitalWorkforceFunctionForm
) => {
  try {
    const response = await instance.post(`${apiPath}/DigitalWorkforce/group`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteReleaseDigitalWorkforceFunctionById = async (id: string) => {
  try {
    const response = await instance.delete(`${apiPath}/DigitalWorkforce/group/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

//客户端

export const getReleaseDigitalWorkforceClientList = async (data?: any) => {
  try {
    const response = await instance.get(`${apiPath}/client`, {
      params: data,
    });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getReleaseDigitalWorkforceClientById = async (id: string) => {
  try {
    const response = await instance.get(`${apiPath}/client/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createReleaseDigitalWorkforceClient = async (
  data: ReleaseDIgitalWorkforceClientItem
) => {
  try {
    const response = await instance.post(`${apiPath}/client`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteReleaseDigitalWorkforceClientById = async (id: string) => {
  try {
    const response = await instance.delete(`${apiPath}/client/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

//用户创建数字员工
export const createSubscribedDigitalWorkforce = async (data: any) => {
  try {
    const response = await instance.post(
      `${apiPath}/SubscribedDigitalWorkforce`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

//订阅列表
export const subscribedDigitalWorkforce = async () => {
  try {
    const response = await instance.get(`${apiPath}/SubscribedDigitalWorkforce`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

//单个数字员工

export const singleSubscribedDigitalWorkforce = async (id: string) => {
  try {
    const response = await instance.get(
      `${apiPath}/SubscribedDigitalWorkforce/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

//禁用 启用
export const updateSubscribedDigitalWorkforceFunctionStatus = async (
  data: any
) => {
  try {
    const response = await instance.post(
      `${apiPath}/SubscribedDigitalWorkforce/Group/Status`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

//配置指定职能
export const updateSubscribedDigitalWorkforceFunctionConf = async (
  data: any
) => {
  try {
    const response = await instance.post(
      `${apiPath}/SubscribedDigitalWorkforce/Group/Config`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getSubscribedDigitalWorkforce = async (id: string) => {
  try {
    const response = await instance.get(
      `${apiPath}/SubscribedDigitalWorkforce/Groups/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
