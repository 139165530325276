import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  Textarea,
  Text,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Select as ChakraSelect } from "chakra-react-select";
import { Formik, Form, Field, FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import { FiCheck } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import { subscribedDigitalWorkforceValidationSchema } from "../../../../utils/validation";
import { domainList } from "../../../../constants/constants";
import { arrayToOptions } from "../../../../utils/common";
import {
  createSubscribedDigitalWorkforce,
  getSubscribedDigitalWorkforce,
  singleSubscribedDigitalWorkforce,
} from "../../../../services/releaseDigitalWorkforce";
import {
  ReleaseDIgitalWorkforceRole,
  SubscribedDigitalWorkforceForm,
} from "../../../../models/releaseDigitalWorkforce";
import { isEmpty } from "lodash";

const EditCustomerDigitalWorkforce: React.FC = () => {
  const { t } = useTranslation();
  const [subscribedDigitalWorkforceList, setSubscribedDigitalWorkforceList] =
    useState<ReleaseDIgitalWorkforceRole[]>([]);
  const [initValues, setInitValues] =
    useState<SubscribedDigitalWorkforceForm>();
  const navigate = useNavigate();
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    {
      label: "menu.digital-workforce",
      path: "/workbench/digital-workforce",
    },
    { label: "Edit", path: "" },
  ];
  const toast = useToast();
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    init();
  }, [id]);

  const findGroupIds = (digitalWorkforceId: string, data: any) => {
    const res = data.digitalWorkforces?.find(
      (r: any) => r.id === digitalWorkforceId
    );
    return !!res ? res.groups.map((group: any) => group.id) : [];
  };
  const init = async () => {
    //先获取订阅的列表
    const itemId = id === "new" || !id ? "00000000-0000-0000-0000-000000000000" : id;
    const res = await getSubscribedDigitalWorkforce(itemId);

    setSubscribedDigitalWorkforceList(res);
    //判断是否有id
    if (!!id && id !== "new") {
      const _res = await singleSubscribedDigitalWorkforce(id);

      const b = res.map((role: ReleaseDIgitalWorkforceRole) => ({
        id: role.id, //role.subscribedDigitalWorkforceId!,
        roleId: role.id,
        roleName: role.name,
        subscribedDWGroupIds: findGroupIds(role.id!, _res),
      }));
      setInitValues({
        id: id,
        name: _res.name,
        domains: _res.domains,
        description: _res.description,
        subscribedDigitalWorkforces: b,
      });
    } else {
      //更新初始化
      setInitValues({
        name: "",
        domains: [],
        description: "",
        subscribedDigitalWorkforces: res.map(
          (role: ReleaseDIgitalWorkforceRole) => ({
            id:  role.id,//role.subscribedDigitalWorkforceId!,
            roleId: role.id,
            roleName: role.name,
            subscribedDWGroupIds: [],
          })
        ),
      });
    }
  };

  //需要盘点个这个id属于这个人 后端
  const handleSubmit = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let opt = "create";
    if (!isEmpty(values.id)) {
      opt = "update";
    }
    createSubscribedDigitalWorkforce(values)
      .then(() => {
        formikHelpers.setSubmitting(false);
        navigate(`/workbench/digital-workforce`);
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        toast({
          title: error?.response?.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
    return;
  };

  if (!initValues) {
    return <Spinner size="xl" />;
  }
  return (
    <Box>
      <Box maxW="1240px" margin="0 auto" minH="30rem" px="1rem">
        <Box py="1rem">
          <CustomBreadcrumb routes={breadcrumbRoutes} />
        </Box>

        <Box bg={"#fff"} p="1rem">
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={subscribedDigitalWorkforceValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => {
              return (
                <Form>
                  <Box my="0.5rem">
                    <Field name="name">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          isRequired
                        >
                          <FormLabel>
                            {t("digital-workforce.workbench.config.name")}:
                          </FormLabel>
                          <Input {...field} />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>

                  <Box my="0.5rem">
                    <Field name="description">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.description && form.touched.description
                          }
                          isRequired
                        >
                          <FormLabel>
                            {t("digital-workforce.workbench.config.desc")}:
                          </FormLabel>
                          <Textarea {...field} minH="10rem" />
                          <FormErrorMessage>
                            {form.errors.description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box my="0.5rem">
                    <Field name="domains">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.domains && form.touched.domains
                          }
                        >
                          <FormLabel>
                            {t("digital-workforce.list.domains")}:
                          </FormLabel>
                          <ChakraSelect
                            isMulti
                            colorScheme="purple"
                            options={arrayToOptions(domainList)}
                            value={arrayToOptions(values.domains)}
                            onChange={(selectedOptions) => {
                              form.setFieldValue(
                                field.name,
                                selectedOptions.map(
                                  (option: any) => option.value
                                )
                              );
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.domains}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <FieldArray
                    name="subscribedDigitalWorkforces"
                    render={() => (
                      <>
                        {values.subscribedDigitalWorkforces &&
                          values?.subscribedDigitalWorkforces.map(
                            (role, index) => (
                              <FormControl key={role.id + index} mb={4}>
                                <Flex mb="0.5rem">
                                  <Text>{t("organization.list.role")}:</Text>
                                  <Text>{role.roleName}</Text>
                                </Flex>
                                <FormLabel>
                                  {t("digital-workforce.workbench.list.group")}:
                                </FormLabel>
                                <ChakraSelect
                                  isMulti
                                  options={subscribedDigitalWorkforceList
                                    ?.find((r) => r.id === role.roleId)
                                    ?.groups!.map((option) => ({
                                      label: option.groupName, // 显示的名称
                                      value: option.id!, // 存储的值为 id
                                    }))}
                                  value={role.subscribedDWGroupIds?.map(
                                    (id) => ({
                                      label: subscribedDigitalWorkforceList
                                        ?.find((r) =>
                                          r?.groups!.some((g) => g.id === id)
                                        )
                                        ?.groups?.find((g) => g.id === id)
                                        ?.groupName!,
                                      value: id!,
                                    })
                                  )}
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      `subscribedDigitalWorkforces[${index}].subscribedDWGroupIds`,
                                      selectedOptions.map(
                                        (option: any) => option.value
                                      ) // 存储 id
                                    );
                                  }}
                                  placeholder="Select roles"
                                />
                              </FormControl>
                            )
                          )}
                      </>
                    )}
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{ md: "flex-end" }}
                  >
                    <Button
                      leftIcon={<FiCheck />}
                      minW="8rem"
                      colorScheme="teal"
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      {t("common.btn.submit")}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default EditCustomerDigitalWorkforce;
