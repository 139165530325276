import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import _, { isArray, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { EditDispatchTaskForm, TasksGroup } from "../../../../models/dispatchTask";
import { createOrUpdateDispatchTask, getDispatchTasksGroups } from "../../../../services/dispatchTasksService";
import { Select as ChakraSelect } from "chakra-react-select";
import { Options } from "../../../../models/common";
import { JSONEditor as CustomEditor } from "../../../../components/JSONEditor";
import "react-datetime/css/react-datetime.css";


interface AddFormProps {
  isOpen: boolean;
  initialValues: EditDispatchTaskForm;
  onOpen: () => void;
  onClose: () => void;
  onSubmit: () => void;
}

const CreateDispatchTask: React.FC<AddFormProps> = ({
  isOpen,
  initialValues,
  onOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [groupList, setGroupList] = useState<TasksGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Options | null>();
  const [executeType, setExecuteType] = useState<any>();
  const [userParametersTreeData, setUserParametersTreeData] = useState<{
    userParametersTree: any
    userParametersSource: any
  }>();

  const taskType = [
    { value: "0", label: t("dispatch-task.types.0") },
    { value: "1", label: t("dispatch-task.types.1") },
  ];


  useEffect(()=>{
    fetchGroupList();
    if(!initialValues?.taskName) {
      setExecuteType('0');
      setSelectedGroup(null);
      setUserParametersTreeData({userParametersTree: null, userParametersSource: null});
    } else {
      setExecuteType(initialValues.executeType?.toString());
    }

  }, [isOpen]);

  const fetchGroupList = () => {
    getDispatchTasksGroups().then((res) => {
      setGroupList(res);
      if(initialValues?.taskName) {
        const item = groupList.find(item => item.id === initialValues.groupId);
        const selected = item ? {
          label: item.groupName,
          value: item.id
        } as Options: null;
        setSelectedGroup(selected);
        const parameters = groupList.find(group => group.id === selected?.value);
        try {
          const jsonObject = JSON.parse(parameters?.userParameters || '');
          const json = {
            userParametersTree: parseParementsToJsonTree(jsonObject),
            userParametersSource: parseParementsToTranslateTree(jsonObject)
          };
          if(initialValues.userParameters) {
            setUserParametersTreeData({
              userParametersSource: json.userParametersSource,
              userParametersTree: JSON.parse(initialValues.userParameters)
            });
          } else {
            setUserParametersTreeData(json);
          }
        } catch{
          setUserParametersTreeData({
            userParametersTree: null,
            userParametersSource: null
          });
        }


      }
    });
  };

  const handleCreate = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let opt = "create";
    if (!isEmpty(values.id)) {
      opt = "update";
    }
    if(userParametersTreeData?.userParametersTree) {
      values = {
        ...values,
        userParameters: JSON.stringify(userParametersTreeData?.userParametersTree)
      }
    } else {
      values = {
        ...values,
        userParameters: ''
      }
    }
    createOrUpdateDispatchTask(values)
      .then((res) => {
        onClose();
        onSubmit();
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((error) => {
        toast({
          title: error?.response?.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  const mapJsonItem = (data: any[] | any) => {
    let obj = {};
      data.map(item => {
        if(item.type === 0) {
          if(item.value_type === 'boolean') {
            obj[item.code] = false;
          } else {
            obj[item.code] = "";
          }
        } else {
          obj[item.code] = mapJsonItem(item.childs) ;
        }
      });
    return [obj];
  }

  const parseParementsToJsonTree = (data: {code: "", name: "", value_type: "string", childs: []}) => {
    if(!_.isObject(data)) {
      return null;
    }
    if(isArray(data)) {
      return  mapJsonItem(data);
    }
    const obj = {};
    obj[data.code] = mapJsonItem(data.childs);
    return obj;
  }

  const mapTranslateItem = (data: any[]) => {
    let obj = {};
    data.map(item => {
      if(item.type === 0) {
        obj[item.code] = item.name;
      } else {
        obj[item.code] = mapTranslateItem(item.childs);
        obj[item.code + '_label'] = item.name;
      }
    });
    return [obj];
  }

  const parseParementsToTranslateTree = (data: {code: "", name: "", value_type: "string", childs: []}) => {
    if(!_.isObject(data)) {
      return null;
    }
    if(isArray(data)) {
      return mapTranslateItem(data)
    }
    const obj = {};
    obj[data.code] = mapTranslateItem(data.childs);
    obj[data.code+'_label'] = data.name;
    return obj;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "50rem" }}>
        <ModalHeader>
          {t("release-digital-workforce.task.create")}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          onSubmit={handleCreate}
        >
          {(props) => (
            <Form>
              <ModalBody>
                 <Box my="0.5rem">
                  <Field name="taskName">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.taskName && form.touched.taskName
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("dispatch-task.name")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.taskName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="groupId">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.groupId && form.touched.groupId
                        }
                        isRequired
                      >
                        <FormLabel>
                        {t("digital-workforce.workbench.list.group")}:
                        </FormLabel>
                        <ChakraSelect
                          colorScheme="purple"
                          value={selectedGroup}
                          options={groupList!.map((option) => ({
                            label: option.groupName, // 显示的名称
                            value: option.id!, // 存储的值为 id
                          } as Options))}
                          onChange={(selectedOptions) =>
                          {
                            setSelectedGroup(selectedOptions);
                            const parameters = groupList.find(group => group.id === selectedOptions?.value);
                            if(parameters?.userParameters) {
                              try {
                                const jsonObject = JSON.parse(parameters?.userParameters || '');
                                  let json = {
                                    userParametersTree: parseParementsToJsonTree(jsonObject),
                                    userParametersSource: parseParementsToTranslateTree(jsonObject)
                                  };

                                if(initialValues.userParameters) {
                                  const item = groupList.find(item => item.id === initialValues.groupId);
                                  const selected = item ? {
                                    label: item.groupName,
                                    value: item.id
                                  } as Options: null;

                                  if(selected?.value === selectedOptions?.value) {
                                    json = {
                                      userParametersSource: parseParementsToTranslateTree(jsonObject),
                                      userParametersTree: JSON.parse(initialValues.userParameters)
                                    };
                                  }
                                }
                                setUserParametersTreeData(json);
                                form.setFieldValue('groupId', selectedOptions?.value || "");
                                form.setFieldValue('conStr', parameters?.cronStr || "");
                              } catch {
                                form.setFieldValue('groupId', selectedOptions?.value || "");
                                form.setFieldValue('conStr', "");
                                form.setFieldValue('userParameters', "");

                                setUserParametersTreeData({
                                  userParametersTree: null,
                                  userParametersSource: null
                                });
                              }
                            } else {
                              form.setFieldValue('groupId', selectedOptions?.value || "");
                              form.setFieldValue('conStr', "");
                              form.setFieldValue('userParameters', "");

                              setUserParametersTreeData({
                                userParametersTree: null,
                                userParametersSource: null
                              });
                            }
                          }
                          }
                        />
                        <FormErrorMessage>
                          {form.errors.functionIds}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                {userParametersTreeData?.userParametersTree &&
                <Box my="0.5rem">
                  <Field name="userParameters">
                  {({ field, form }: any) => (
                  <FormControl>
                    <div className="border-div">
                    <CustomEditor
                      data={userParametersTreeData?.userParametersTree}
                      source={userParametersTreeData?.userParametersSource}
                      collapsible
                      onChange={(key, parent, type, e)=>{
                        setUserParametersTreeData({
                        ...userParametersTreeData,
                        userParametersTree: e,
                      });
                    }}
                      ></CustomEditor>
                    </div>
                  </FormControl>
                )}
                  </Field>
              </Box>
                }
                <Box my="0.5rem">
                  <Field name="executeType">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.executeType && form.touched.executeType
                        }
                        isRequired
                      >
                        <FormLabel>
                        {t("dispatch-task.trigger-type")}:
                        </FormLabel>
                        <ChakraSelect
                          colorScheme="purple"
                          options={taskType}
                          value={taskType.find(item => item.value === executeType)}
                          defaultValue={taskType[0]}
                          onChange={(selectedOption) => {
                            setExecuteType(selectedOption?.value);
                            form.setFieldValue("executeType", selectedOption?.value || 0);
                          }}
                        />
                        <FormErrorMessage>
                          {form.errors.executeType}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                {executeType === "1" && <Box my="0.5rem">
                  <Field name="cronStr">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.executeType && form.touched.executeType
                        }
                        isRequired
                      >
                        <FormLabel>
                        {t("dispatch-task.trigger-time")}:
                        </FormLabel>
                        <Input {...field} value={initialValues.cronStr} />
                        <FormErrorMessage>
                          {form.errors.executeType}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>}

              </ModalBody>
              <ModalFooter>
                <Button type="submit" size="sm" colorScheme="teal" mr={3}>
                  {t("common.btn.submit")}
                </Button>
                <Button size="sm" onClick={onClose}>
                  {t("common.btn.cancel")}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default CreateDispatchTask;
