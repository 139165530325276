import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { InvoiceItem } from "../../../../models/invoice";
import { getInvoiceList } from "../../../../services/invoiceService";
import { canEdit } from "../../../../utils/auth";
import InvoiceDrawer from "../Detail";
import { Field, FieldProps, Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment-timezone";

interface UploadProps {
  refresh: number;
}
const InvoiceList: React.FC<UploadProps> = ({refresh}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [invoice, setInvoice] = useState<InvoiceItem>();
  const [tableList, setTableList] = useState<InvoiceItem[]>([]);

  const tableFiled = [
    t("invoice.fields.buyer"),
    t("invoice.fields.seller"),
    t("invoice.fields.created_date"),
    t("invoice.fields.invoice_no"),
    t("invoice.fields.total_amount"),
    t("invoice.fields.tax_excluded_amount"),
    t("invoice.fields.tax_amount"),
    t("invoice.fields.remark"),
    t("invoice.fields.owner"),
    t("invoice.fields.invoice_detail.name"),
  ];

  useEffect(() => {
    fetchInvoiceList();
  }, [refresh]);

  const fetchInvoiceList = (data?: { sellerName?: string, buyerName?: string, invoiceNumber?: string, startDate?: string, endDate?: string }) => {
    getInvoiceList(data).then((res) => {
      setTableList(_.orderBy(res, "createdDate", "desc"));
    });
  };

    const {
      isOpen: isInvoiceVisible,
      onOpen: onInvoiceDrawerOpen,
      onClose: onInvoiceDrawerClose,
    } = useDisclosure();

  const _renderCell = (
    row: InvoiceItem,
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td
        py="0.5rem"
        {...extraProps}
        pl="0.2rem"
      >
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const _renderTableRow = (row: InvoiceItem, index: number) => {
    return (
      <Tr
        key={index}
        _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
      >
        {_renderCell(row, row.buyerName, "sm", "bold", { minW: "200px" })}
        {_renderCell(row, row.sellerName, "sm", "bold", { minW: "200px" })}
        {_renderCell(row, moment(row.issueTime).format("yyyy-MM-DD"), "sm", "normal")}
        {_renderCell(row, row.invoiceNumber, "md", "normal")}
        {_renderCell(row, row.totalTaxincludedAmount, "md", "normal")}
        {_renderCell(row, row.totalAmWithoutTax, "md", "normal")}
        {_renderCell(row, row.totalTaxAm, "md", "normal")}
        {_renderCell(row, row.remark, "md", "normal")}
        {_renderCell(row, row.drawer, "md", "normal")}
        <Td py="0.5rem" pl="0.2rem">
          {canEdit() && (
            <Button
              size="sm"
              mr="0.5rem"
              onClick={() => {
                setInvoice(row);
                onInvoiceDrawerOpen();
              }}
            >
              {t("common.btn.view")}
            </Button>
          )}
        </Td>
      </Tr>
    );
  };

  const handleSubmit = (
    values: { sellerName?: string, buyerName?: string, invoiceNumber?: string, startDate?: string, endDate?: string },
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if(!!values.startDate && !!values.endDate) {
      const startDate = moment(values.startDate);
      const endDate = moment(values.endDate);
      if(startDate.diff(endDate) > 0) {
        toast({
          title: t('invoice.error.date-range'),
          position: "top",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        formikHelpers.setSubmitting(false);
        return;
      }
    }
    const filters = _.pickBy(values, item => !!item);
    fetchInvoiceList(filters);
    formikHelpers.setSubmitting(false);
  }

  return (
    <>
          <Box mb="1rem" p="1rem" bgColor="#fff">
            <Formik
              initialValues={{ sellerName: "", buyerName: "", invoiceNumber: "", startDate: "",endDate: "" }}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <Form dir="row">
                  <Flex columnGap="1rem" alignItems={"center"}>
                  <Field name="sellerName">
                    {({ field, form }) => (
                      <FormControl
                      flexBasis="200px"
                      >
                       <Input
                            {...field}
                            size="md"
                            placeholder={t(
                              "invoice.fields.seller"
                            )}
                            value={field.value.sellerName}
                          />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="buyerName">
                    {({ field, form }) => (
                      <FormControl
                      flexBasis="200px"
                      >
                         <Input
                            {...field}
                            size="md"
                            placeholder={t(
                              "invoice.fields.buyer"
                            )}
                          />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="invoiceNumber">
                    {({ field, form }) => (
                      <FormControl
                      flexBasis="200px"
                      >
                        <Input
                            {...field}
                            size="md"
                            placeholder={t(
                              "invoice.fields.invoice_no"
                            )}
                          />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="startDate">
                      {({ field, form }: any) => (
                        <FormControl flexBasis="200px">
                          <Input
                            {...field}
                            placeholder={t("case.create.start-date")}
                            minW="200px"
                            type="date"
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="endDate">
                      {({ field, form }: any) => (
                        <FormControl flexBasis="200px">
                          <Input
                            {...field}
                            placeholder={t("case.create.end-date")}
                            minW="200px"
                            type="date"
                          />
                        </FormControl>
                      )}
                    </Field>
                  <Button
                    colorScheme="teal"
                    type="submit"
                    size="sm"
                    ml="1rem"
                    isLoading={formikProps.isSubmitting}
                  >
                    {t("common.btn.search")}
                  </Button>
                  </Flex>
                  
                </Form>
              )}
            </Formik>
          </Box>
    <Box>
      <Box p="1rem" bgColor="#fff" overflowX={{ base: "scroll", md: "hidden" }}>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {tableList.map((row: InvoiceItem, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
    <InvoiceDrawer
     isOpen={isInvoiceVisible}
     initialValues={invoice!}
     onOpen={onInvoiceDrawerOpen}
     onClose={onInvoiceDrawerClose}
    ></InvoiceDrawer>
    </>
  );
};

export default InvoiceList;
